button.btn.btn-primary {
  color: white;
  background: transparent;
  border: transparent;
  outline: transparent;
}

button.btn.btn-primary:hover {
  color: black;
  background: whitesmoke;
}

button.btn.btn-primary:focus {
  border: transparent;
}
